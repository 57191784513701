export default function HeroMobile() {
    return (
      <div className="relative z-30 font-sans min-h-screen flex flex-col justify-center bg-[#e05257] overflow-hidden">
        {/* Background pattern with subtle movement */}
        <div className="absolute inset-0">
          <svg
            className="absolute -left-20 -top-16 w-[500px] opacity-20 animate-float"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 400"
          >
            <circle cx="200" cy="200" r="200" fill="white" />
          </svg>
          <svg
            className="absolute -right-16 -bottom-16 w-[300px] opacity-30 animate-float-reverse"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 400"
          >
            <circle cx="200" cy="200" r="200" fill="white" />
          </svg>
        </div>
  
        <div className="relative px-6 py-16 sm:px-6 sm:py-10 lg:px-8 -mt-24">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-[2.50rem]">
              Dreirosen Apotheke
            </h2>
            <p className="mt-3 text-xl font-bold tracking-tight text-white sm:text-[2.30rem]">
              Mit uns blibbts glaibasel gsund
            </p>
            <p className="mx-auto mt-5 max-w-xl text-lg leading-8 text-white opacity-90">
              Incididunt sint fugiat pariatur cupidatat
            </p>
  
            {/* Button with a glowing effect */}
            <div className="mt-5 flex items-center justify-center gap-x-6">
              <a
                href="/products"
                className="rounded-full bg-white px-5 py-2.5 text-sm font-semibold text-black shadow-lg transition-all duration-300 ease-in-out hover:bg-gray-200 hover:shadow-2xl focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 animate-bubble hover:scale-110 hover:shadow-[0_4px_20px_rgba(255,255,255,0.4)]"
              >
                Zum Shop
              </a>
            </div>
  
            <div className="mt-5 flex items-center justify-center gap-x-6">
              <a href="#" className="text-sm font-semibold leading-6 text-white opacity-90 hover:text-gray-300 transition-all duration-200 ease-in-out">
                Weitere Infos <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
  
        {/* Animation styles */}
        <style jsx>{`
          @keyframes bubbleEffect {
            0% {
              transform: scale(0.5);
              opacity: 0;
            }
            80% {
              transform: scale(1.05);
              opacity: 1;
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes float {
            0% {
              transform: translateY(0px);
            }
            50% {
              transform: translateY(-20px);
            }
            100% {
              transform: translateY(0px);
            }
          }
          @keyframes float-reverse {
            0% {
              transform: translateY(0px);
            }
            50% {
              transform: translateY(20px);
            }
            100% {
              transform: translateY(0px);
            }
          }
          .animate-float {
            animation: float 6s ease-in-out infinite;
          }
          .animate-float-reverse {
            animation: float-reverse 6s ease-in-out infinite;
          }
          .animate-bubble {
            animation: bubbleEffect 0.7s ease-out;
          }
        `}</style>
      </div>
    );
  }
  