import { motion } from 'framer-motion';
import { ShoppingCartIcon, CreditCardIcon, TruckIcon, CheckCircleIcon } from '@heroicons/react/24/outline';


export default function Services() {
  const cardVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: (order) => ({
      scale: 1,
      opacity: 1,
      transition: {
        delay: order * 0.1,
        type: "spring",
        stiffness: 100,
        damping: 10,
        duration: 0.8,
      },
    }),
  };


  return (
    <div className="relative z-20 -mt-36">
      <div className="mx-auto max-w-8xl px-6 lg:px-8">
        <motion.div
          className="mx-auto flex max-w-2xl flex-col gap-3 lg:mx-0 lg:max-w-none lg:flex-row lg:items-end"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          {/* Section 1 */}
          <motion.div
            custom={1}
            variants={cardVariants}
            className="relative flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-3xl bg-gradient-to-r from-[#60a465] to-[#8bc48a] p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-32 shadow-lg"
            whileHover={{
              scale: 1.03,
              y: -8,
              transition: { type: "spring", stiffness: 300, damping: 20 }
            }}
          >
            <p className="flex-none text-3xl font-extrabold tracking-tight text-white">+100'000 Produkte</p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-white">
              Ob für Gsundheit, Schönheits- oder Pflegeprodukte – bi eus findisch immer, was du bruchsch.
              </p>
              <p className="mt-2 text-base leading-7 text-gray-100">
                
              </p>
            </div>
          </motion.div>
         
          {/* Section 2 */}
          <motion.div
            custom={3}
            variants={cardVariants}
            className="relative flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-3xl bg-gray-100 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-18 shadow-lg"
            whileHover={{
              scale: 1.03,
              y: -8,
              transition: { type: "spring", stiffness: 300, damping: 20 }
            }}
          >
            <p className="flex-none text-3xl font-bold tracking-tight text-black">Labor Diagnostik</p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-black">Schnell und Zuverlässig</p>
              <p className="mt-2 text-base leading-7 text-black">
              Sicheri und schnelle Diagnosen – vertraue uf üsere Kompetenz i de Labor Diagnostik.
              </p>
            </div>
          </motion.div>
         
          {/* Section 3 */}
          <motion.div
            custom={5}
            variants={cardVariants}
            className="relative flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-3xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start shadow-lg"
            whileHover={{
              scale: 1.03,
              y: -8,
              transition: { type: "spring", stiffness: 300, damping: 20 }
            }}
          >
            <p className="flex-none text-2xl font-bold tracking-tight text-gray-900">Stressfrei shoppen</p>
            <div className="flex justify-between items-center space-x-4 mt-3">
              <div className="flex items-center justify-center p-4 bg-gradient-to-r from-[#4e8451] to-[#71ae75] rounded-full shadow-lg">
                <ShoppingCartIcon className="w-9 h-9 text-white" />
              </div>
              <div className="flex items-center justify-center p-4 bg-gradient-to-r from-[#4e8451] to-[#71ae75] rounded-full shadow-lg">
                <CheckCircleIcon className="w-9 h-9 text-white" />
              </div>
              <div className="flex items-center justify-center p-4 bg-gradient-to-r from-[#4e8451] to-[#71ae75] rounded-full shadow-lg">
                <TruckIcon className="w-9 h-9 text-white" />
              </div>
            </div>
          </motion.div>

         
          {/* Section 4 */}
          <motion.div
            custom={4}
            variants={cardVariants}
            className="relative flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-3xl bg-[#e05257] p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-18 shadow-lg"
            whileHover={{
              scale: 1.03,
              y: -8,
              transition: { type: "spring", stiffness: 300, damping: 20 }
            }}
          >
            <p className="flex-none text-3xl font-bold tracking-tight text-white">Dini persönligi Apotheke</p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-white"></p>
              <p className="mt-2 text-base leading-7 text-white">
              Individuell uf dii Bedürfnisse agpasst: Mit personalisierte Vorschläg und schnällere Bestellprozess..
              </p>
            </div>
          </motion.div>
         
          {/* Section 5 */}
          <motion.div
            custom={2}
            variants={cardVariants}
            className="relative flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-3xl bg-gradient-to-r from-[#658c7f] to-[#8bc48a] p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-32 shadow-lg"
            whileHover={{
              scale: 1.03,
              y: -8,
              transition: { type: "spring", stiffness: 300, damping: 20 }
            }}
          >
            <p className="flex-none text-3xl font-bold tracking-tight text-white border-b border-white pb-2">
              Spezielle Aktione für dii
            </p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-white">
                Mit üse regelmässige Rabattä und Sonderaktione chasch du dini Gsundheitsvorsorg günstiger mache.
              </p>
              <p className="mt-2 text-base leading-7 text-white">
              </p>
            </div>
          </motion.div>

         
        </motion.div>
      </div>
    </div>
  );
}
