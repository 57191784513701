import React, { useEffect, useState, useRef } from 'react';
import { FaHeart, FaRegHeart, FaStar, FaRegStar } from 'react-icons/fa';

export default function DiscountProducts() {
  const initialProducts = [
    { id: 1, title: 'Clear Pore Scrub', price: '$49.99', image: 'images/skincare.png', rating: 4 },
    { id: 2, title: 'Avene Cream', price: '$39.99', image: 'images/avene.avif', rating: 5 },
    { id: 3, title: 'La Roche Possay Set', price: '$29.99', image: 'images/products5.png', rating: 3 },
    { id: 4, title: 'Lizerarle', price: '$59.99', image: 'images/product2.jpg', rating: 4 },
    { id: 5, title: 'La roche possay', price: '$19.99', image: 'images/sunscreen.jfif', rating: 2 },
    { id: 1, title: 'Clear Pore Scrub', price: '$49.99', image: 'images/skincare.png', rating: 4 },
    { id: 2, title: 'Avene Cream', price: '$39.99', image: 'images/avene.avif', rating: 5 },
  ];

  const [products, setProducts] = useState(initialProducts);
  const [scrollY, setScrollY] = useState(0);
  const scrollContainerRef = useRef(null);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleFavorite = (id) => {
    const updatedProducts = products.map((product) =>
      product.id === id
        ? { ...product, isFavorite: !product.isFavorite }
        : product
    );
    setProducts(updatedProducts);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  return (
    <>
      <style>
        {`
          .no-scrollbar::-webkit-scrollbar {
            display: none;
          }

          .no-scrollbar {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
          }

          .product-container {
            scroll-snap-type: x mandatory;
            scroll-behavior: smooth;
            padding-bottom: 20px; /* Increase bottom padding to give more space */
          }

          .product-card {
            scroll-snap-align: center;
            transform-origin: center;
            position: relative; /* Ensure absolute positioning for favorite icon */
          }

          .product-card:hover {
            transform: scale(1.05);
            transition: transform 0.3s ease-in-out;
            z-index: 50;
          }

          /* Positioning for the favorites icon */
          .favorite-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 50%;
            padding: 6px;
            cursor: pointer;
            transition: background-color 0.3s;
          }

          .favorite-icon:hover {
            background-color: rgba(255, 255, 255, 1);
          }

          .star-rating {
            display: flex;
            justify-content: center;
            margin-top: 4px;
          }

          .star {
            color: #FFD700;
            margin: 0 2px;
          }

          .star-empty {
            color: #e0e0e0;
          }

          @media (max-width: 768px) {
            .product-card {
              flex: 0 0 auto;
              width: 80%;
              margin: 0 auto;
            }
          }
        `}
      </style>

      <div className="relative w-full bg-white rounded-3xl mx-auto mt-0 px-6 lg:px-12 mt-20 mb-32 overflow-visible">
        {/* Top Section */}
        <div className="container mx-auto relative z-10">
          <h2 className="text-4xl font-bold text-center text-black">Rabattknüller</h2>
          <p className="text-center text-gray-700 mt-4">Entdeck d'meistverkaufte Produkt vom Monet</p>

          <div className="mt-12 flex items-center">
            {/* Left Scroll Button */}
            <button
              onClick={scrollLeft}
              className="p-3 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors duration-300"
            >
              &#8592;
            </button>

            {/* Scrollable Product Cards */}
            <div
              ref={scrollContainerRef}
              className="flex space-x-6 overflow-x-auto no-scrollbar scroll-smooth product-container"
            >
              {products.map((product) => (
                <div
                  key={product.id}
                  className="bg-white rounded-lg shadow-md p-6 hover:shadow-md transition-shadow duration-300 flex flex-col items-center justify-center min-w-[250px] product-card relative z-10 hover:z-50 transform hover:scale-110"
                >
                  {/* Favorite Icon */}
                  <div
                    className="favorite-icon"
                    onClick={() => toggleFavorite(product.id)}
                  >
                    {product.isFavorite ? (
                      <FaHeart className="text-red-500" size={20} />
                    ) : (
                      <FaRegHeart className="text-red-500" size={20} />
                    )}
                  </div>

                  <img
                    src={product.image}
                    alt={product.title}
                    className="w-full h-40 object-contain rounded-t-lg"
                  />
                  <div className="mt-4 text-center">
                    <h3 className="text-lg font-semibold text-gray-800">{product.title}</h3>
                    <p className="text-[#e05257] mt-2">{product.price}</p>

                    {/* Star Rating */}
                    <div className="star-rating">
                      {[...Array(5)].map((_, i) => (
                        i < product.rating ? (
                          <FaStar key={i} className="star" size={16} />
                        ) : (
                          <FaRegStar key={i} className="star star-empty" size={16} />
                        )
                      ))}
                    </div>
                  </div>

                  <button className="mt-4 w-full text-[#e05257] py-2 rounded-lg transition-colors duration-300">
                    View Product
                  </button>
                </div>
              ))}
            </div>

            {/* Right Scroll Button */}
            <button
              onClick={scrollRight}
              className="p-3 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors duration-300"
            >
              &#8594;
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
