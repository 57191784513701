import React, { useState, useEffect } from 'react';
import { Popover } from '@headlessui/react';
import {
  Bars3Icon,
  BookmarkSquareIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ComputerDesktopIcon,
  CursorArrowRaysIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  NewspaperIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  UserGroupIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
  ShoppingCartIcon,
  UserCircleIcon // Keep this import here, remove the duplicate
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link, useNavigate } from 'react-router-dom';
import { getProfile, logoutUser } from '../services/apiService'; // Assuming the same services are used
import { HeartIcon } from '@heroicons/react/24/solid'; // Import only HeartIcon


const solutions = [
  {
    name: 'Lorem',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '#',
    icon: ChartBarIcon,
  },
  {
    name: 'Lorem',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    href: '#',
    icon: CursorArrowRaysIcon,
  },
  { name: 'Lorem', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', href: '#', icon: ShieldCheckIcon },
  {
    name: 'lorem',
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    href: '#',
    icon: Squares2X2Icon,
  },
  {
    name: 'Lorem',
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    href: '#',
    icon: Squares2X2Icon,
  },
];

const callsToAction = [
  { name: 'Watch Tutorial', href: '#', icon: PlayIcon },
  { name: 'View All Products', href: '#', icon: CheckCircleIcon },
  { name: 'Contact Us', href: '#', icon: PhoneIcon },
];

const company = [
  { name: 'About', href: '#', icon: InformationCircleIcon },
  { name: 'Customers', href: '#', icon: BuildingOfficeIcon },
  { name: 'Lorem', href: '#', icon: NewspaperIcon },
  { name: 'Lorem', href: '#', icon: BriefcaseIcon },
  { name: 'Privacy', href: '#', icon: ShieldCheckIcon },
];

const resources = [
  { name: 'Lorem', href: '#', icon: UserGroupIcon },
  { name: 'Partners', href: '#', icon: GlobeAltIcon },
  { name: 'Guides', href: '#', icon: BookmarkSquareIcon },
  { name: 'Lorem', href: '#', icon: ComputerDesktopIcon },
];

const blogPosts = [
  {
    id: 1,
    name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    href: '#',
    preview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imageUrl:
      'https://images.unsplash.com/photo-1558478551-1a378f63328e',
  },
  {
    id: 2,
    name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    href: '#',
    preview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imageUrl:
      'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d',
  },
];

export default function Example() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsLoggedIn(true);
        try {
          const profileData = await getProfile();
          setUser(profileData.data.user);
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, []);

  const handleLogout = () => {
    logoutUser();
    setIsLoggedIn(false);
    setUser(null);
    navigate('/loginpage');
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
<Popover className={`sticky top-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-white shadow-lg py-3' : 'bg-white py-2'}`}>
      <div className="relative z-20">
        <div className="mx-auto flex max-w-8xl items-center justify-between px-6 sm:py-0 md:justify-start md:space-x-10 lg:px-8">
          <div>
            <a href="/" className="flex">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src='/images/logo.jpg'
                className={`h-12 w-auto transition-all duration-300 ${isScrolled ? 'h-10 sm:h-20' : 'h-16 sm:h-24'}`}
              />
            </a>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#53a751]">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex md:flex-1 md:items-center md:justify-between w-full">
            <Popover.Group as="nav" className="flex space-x-10">
              <Popover>
                <Popover.Button className="group inline-flex items-center rounded-md bg-white text-base font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#53a751] focus:ring-offset-2 data-[open]:text-gray-900">
                  <span>Menu</span>
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-data-[open]:text-gray-600 group-data-[open]:group-hover:text-gray-500"
                  />
                </Popover.Button>
                <Popover.Panel
                  transition
                  className="absolute inset-x-0 top-full z-10 hidden transform bg-white shadow-lg transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in md:block"
                >
                  <div className="mx-auto grid max-w-7xl gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                    {solutions.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 flex flex-col justify-between rounded-lg p-3 hover:bg-gray-50"
                      >
                        <div className="flex md:h-full lg:flex-col">
                          <div className="flex-shrink-0">
                            <span className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-[#53a751] text-white sm:h-12 sm:w-12">
                              <item.icon aria-hidden="true" className="h-6 w-6" />
                            </span>
                          </div>
                          <div className="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                            <div>
                              <p className="text-base font-medium text-gray-900">{item.name}</p>
                              <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                            </div>
                            <p className="mt-2 text-sm font-medium text-[#537b67] lg:mt-4">
                              Learn more
                              <span aria-hidden="true"> &rarr;</span>
                            </p>
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                  <div className="bg-gray-50">
                    <div className="mx-auto max-w-7xl space-y-6 px-4 py-5 sm:flex sm:space-x-10 sm:space-y-0 sm:px-6 lg:px-8">
                      {callsToAction.map((item) => (
                        <div key={item.name} className="flow-root">
                          <a
                            href={item.href}
                            className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                          >
                            <item.icon aria-hidden="true" className="h-6 w-6 flex-shrink-0 text-gray-400" />
                            <span className="ml-3">{item.name}</span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Popover>
              <a href="/products" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Shop
              </a>
              <a href="/vacinationspage" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Labor
              </a>
              <Popover>
                <Popover.Button className="group inline-flex items-center rounded-md bg-white text-base font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-[#53a751] focus:ring-offset-2 data-[open]:text-gray-900">
                  <span>More</span>
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-data-[open]:text-gray-600 group-data-[open]:group-hover:text-gray-500"
                  />
                </Popover.Button>
                <Popover.Panel
                  transition
                  className="absolute inset-x-0 top-full z-10 hidden transform shadow-lg transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in md:block"
                >
                  <div className="absolute inset-0 flex">
                    <div className="w-1/2 bg-white" />
                    <div className="w-1/2 bg-gray-50" />
                  </div>
                  <div className="relative mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                    <nav className="grid gap-y-10 bg-white px-4 py-8 sm:grid-cols-2 sm:gap-x-8 sm:px-6 sm:py-12 lg:px-8 xl:pr-12">
                      <div>
                        <h3 className="text-base font-medium text-gray-500">Company</h3>
                        <ul role="list" className="mt-5 space-y-6">
                          {company.map((item) => (
                            <li key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                              >
                                <item.icon aria-hidden="true" className="h-6 w-6 flex-shrink-0 text-gray-400" />
                                <span className="ml-4">{item.name}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <h3 className="text-base font-medium text-gray-500">Resources</h3>
                        <ul role="list" className="mt-5 space-y-6">
                          {resources.map((item) => (
                            <li key={item.name} className="flow-root">
                              <a
                                href={item.href}
                                className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-50"
                              >
                                <item.icon aria-hidden="true" className="h-6 w-6 flex-shrink-0 text-gray-400" />
                                <span className="ml-4">{item.name}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </nav>
                    <div className="bg-gray-50 px-4 py-8 sm:px-6 sm:py-12 lg:px-8 xl:pl-12">
                      <div>
                        <h3 className="text-base font-medium text-gray-500">From the blog</h3>
                        <ul role="list" className="mt-6 space-y-6">
                          {blogPosts.map((post) => (
                            <li key={post.id} className="flow-root">
                              <a href={post.href} className="-m-3 flex rounded-lg p-3 hover:bg-gray-100">
                                <div className="hidden flex-shrink-0 sm:block">
                                  <img alt="" src={post.imageUrl} className="h-20 w-32 rounded-md object-cover" />
                                </div>
                                <div className="w-0 flex-1 sm:ml-8">
                                  <h4 className="truncate text-base font-medium text-gray-900">{post.name}</h4>
                                  <p className="mt-1 text-sm text-gray-500">{post.preview}</p>
                                </div>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="mt-6 text-sm font-medium">
                        <a href="#" className="text-[#53a751]">
                          View all posts
                          <span aria-hidden="true"> &rarr;</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Popover>
            </Popover.Group>

            {/* Search bar */}
            <div className="relative w-full sm:w-[150px] md:w-[250px] lg:w-[350px] xl:w-[450px] ml-10">
  <input
    type="text"
    className="block w-full bg-gray-100 rounded-lg border-gray-100 py-2 pl-4 pr-12 text-sm placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500"
    placeholder="Search..."
  />
  <div className="absolute inset-y-0 right-0 flex items-center pr-3">
    <button
      type="submit"
      className="flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
    >
      <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
    </button>
  </div>
</div>


            <div className="flex items-center md:ml-12">
              {!isLoggedIn ? (
                <>
                  <a href="/loginpage" className="ml-4 text-base font-medium text-gray-500 hover:text-gray-900">
                    Sign in
                  </a>
                  <a
                    href="/registerpage"
                    className="ml-8 inline-flex items-center justify-center rounded-lg border border-transparent bg-[#60a465] px-6 py-2 text-base font-medium text-white shadow-sm hover:bg-[#4C8350]"
                  >
                    Sign up
                  </a>
                  <a href="/cartpage" className="ml-6 p-2 bg-gray-100 rounded-lg text-gray-500 hover:text-gray-900 hover:bg-gray-200">
                    <ShoppingCartIcon className="h-6 w-6" aria-hidden="true" />
                  </a>

                </>
              ) : (
                <>
                  <span className="ml-4 text-base font-medium text-gray-500">
                    {user && `${user.name} ${user.surname}`}
                  </span>

                  <a href="/profilepage" className="ml-6 text-gray-500 hover:text-gray-900">
                    <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </a>

                  {/* Add Heart Icon with a link to user's favorite page */}
                  <Link to="/userfavoritespage" className="ml-6 text-gray-500 hover:text-gray-900">
                    <HeartIcon className="h-6 w-6" aria-hidden="true" />
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Popover.Panel
  transition
  className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in md:hidden max-h-[100vh] overflow-y-auto"
>
  <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
    <div className="px-5 pb-6 pt-5 sm:pb-8">
      <div className="flex items-center justify-between">
        <div className="-mr-2">
          <Popover.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#53a751]">
            <span className="absolute -inset-0.5" />
            <span className="sr-only">Close menu</span>
            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
          </Popover.Button>
        </div>
      </div>
      
      {/* Add the search bar here */}
      <div className="mt-4">
        <div className="relative">
          <input
            type="text"
            className="block w-full bg-gray-100 rounded-lg border-gray-100 py-2 pl-4 pr-12 text-sm placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="Search..."
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <button
              type="submit"
              className="flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
            >
              <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      <div className="mt-6 sm:mt-8">
        <nav>
          <div className="grid gap-7 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-8">
            {solutions.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
              >
                <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-[#53a751] text-white sm:h-12 sm:w-12">
                  <item.icon aria-hidden="true" className="h-6 w-6" />
                </div>
                <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
              </a>
            ))}
          </div>
          <div className="mt-8 text-base">
            <a href="#" className="font-medium text-[#53a751]">
              View all products
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </nav>
      </div>
    </div>
    <div className="px-5 py-6">
      <div className="grid grid-cols-2 gap-4">
        <a href="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
          Pricing
        </a>
        <a href="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
          Docs
        </a>
        <a href="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
          Company
        </a>
        <a href="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
          Resources
        </a>
        <a href="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
          Blog
        </a>
        <a href="#" className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
          Contact Sales
        </a>
      </div>
      <div className="mt-6">
        <a
          href="#"
          className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#53a751] px-4 py-2 text-base font-medium text-white shadow-sm"
        >
          Sign up
        </a>
        <p className="mt-6 text-center text-base font-medium text-gray-500">
          Existing customer?{' '}
          <a href="#" className="text-[#53a751]">
            Sign in
          </a>
        </p>
      </div>
    </div>
  </div>
</Popover.Panel>


    </Popover>
  );
}
