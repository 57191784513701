import React from 'react';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Banner from '../components/Banner';
import Hero from '../components/Home/Hero';
import Services from '../components/Home/Services';
import BestSellers from '../components/Home/BestSellers';
import BlogSection from '../components/Home/BlogSection';
import PhoneSection from '../components/Home/PhoneSection';
import HelpContent from '../components/Home/HelpContent';
import DiscountProducts from '../components/Home/DiscountProducts';
import ServicesMobile from '../components/Home/ServicesMobile';
import HeroMobile from '../components/Home/HeroMobile';
import LabServices from '../components/Home/LabServices';

function Home() {
  return (
    <div>
      <div className="hidden md:block">
      <Banner />
      </div>
      <Nav />
      <div className="hidden md:block">
      <Hero />
      </div>
      <div className="block md:hidden">
      <HeroMobile />
      </div>
      
      {/* Show Services on larger screens and hide on mobile */}
      <div className="hidden md:block">
        <Services />
      </div>

      {/* Show ServicesMobile on mobile and hide on larger screens */}
      <div className="block md:hidden">
        <ServicesMobile />
      </div>

      <HelpContent />
      <BestSellers /> 
      <DiscountProducts />
      <LabServices />
      <BlogSection />
      <PhoneSection />
      <Footer />
    </div>
  );
}

export default Home;
