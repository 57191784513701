import { useState, useEffect } from 'react';
import { fetchFavorites, toggleFavoriteProduct } from '../services/apiService';
import { HeartIcon as HeartIconSolid, HeartIcon as HeartIconOutline } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

export default function UserFavorites() {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getFavorites = async () => {
      try {
        const response = await fetchFavorites();
        setFavorites(response.data.favorites); // Update state with favorite products
        setLoading(false); // Stop loading
      } catch (error) {
        console.error('Error fetching favorites:', error);
        setLoading(false); // Stop loading even if there's an error
      }
    };
    getFavorites();
  }, []);

  const handleViewProduct = (pharmacode) => {
    navigate(`/product/${pharmacode}`);
  };

  const handleToggleFavorite = async (pharmacode) => {
    try {
      await toggleFavoriteProduct(pharmacode);
      // Remove the unfavorited product from the favorites list
      setFavorites((prevFavorites) =>
        prevFavorites.filter((product) =>
          product.articles.every((article) => article.pharmacode !== pharmacode)
        )
      );
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 pb-6 pt-24">Your Favorite Products</h1>

        <section aria-labelledby="favorites-heading" className="pb-24 pt-6 grid grid-cols-1 lg:grid-cols-4 gap-x-8">
          {/* Favorites grid */}
          <div className="lg:col-span-3 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3">
            {favorites.length === 0 ? (
              <p className="text-lg text-gray-500">You have no favorite products yet.</p>
            ) : (
              favorites.map((product) =>
                product.articles.map((article) => (
                  <div
                    key={article.pharmacode}
                    className="group relative border border-gray-200 rounded-lg bg-white shadow-sm p-4 sm:p-6 flex flex-col hover:shadow-lg transition-shadow duration-200"
                  >
                    <button
                      onClick={() => handleToggleFavorite(article.pharmacode)}
                      className="absolute top-2 right-2"
                    >
                      <HeartIconSolid className="h-6 w-6 text-[#e05257]" />
                    </button>

                    <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-white group-hover:opacity-90">
                      {article.hasPhoto && article.images && article.images.M?.length > 0 ? (
                        <img
                          src={`http://localhost:3000/${article.images.M[0].replace(/\\/g, '/')}`}
                          alt={article.description?.description || 'No description'}
                          className="h-full w-full object-contain object-center"
                        />
                      ) : (
                        <div className="h-full w-full bg-white flex items-center justify-center text-gray-500">
                          <span>No Image Available</span>
                        </div>
                      )}
                    </div>

                    <div className="flex-grow pb-4 pt-4 text-center">
                      <h3 className="text-sm font-medium text-gray-900">
                        {article.description?.description || 'Description not available'}
                      </h3>
                      <p className="italic text-gray-500">
                        {article.description?.longDescription || 'Details not available'}
                      </p>

                      {/* Price */}
                      {article.prices && article.prices.length > 0 ? (
                        article.prices.find((price) => price.type.description === 'Publikumspreis') ? (
                          <p className="mt-2 font-medium text-gray-900">
                            {article.prices.find((price) => price.type.description === 'Publikumspreis').price}{' '}
                            {article.prices[0].type.description}
                          </p>
                        ) : (
                          <p className="mt-2 font-medium text-gray-900">Price not available</p>
                        )
                      ) : (
                        <p className="mt-2 font-medium text-gray-900">Price not available</p>
                      )}
                    </div>

                    {/* View Product Button */}
                    <div className="mt-4 flex space-x-2">
                      <button
                        onClick={() => handleViewProduct(article.pharmacode)}
                        type="button"
                        className="w-full flex items-center justify-center rounded-lg border-2 border-[#4c8350] bg-[#60a465] py-2 px-6 text-sm font-semibold text-white transition-all duration-300 hover:bg-[#4c8350] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#60a465]"
                      >
                        View Product
                      </button>
                    </div>
                  </div>
                ))
              )
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
