import { useEffect } from 'react';

export default function HelpContent() {
  useEffect(() => {
    const video = document.querySelector('video');
    if (video) {
      video.muted = true;
      video.play().catch(error => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);

  return (
    <div className="bg-[#e05257] py-16 sm:py-24 lg:py-10 mt-32 shadow-xl">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="max-w-2xl text-center lg:text-left text-3xl font-bold tracking-tight text-white">
            <h2 className="inline sm:block text-3xl sm:text-4xl">Du hesch Kopfweh oder zue Nasä?</h2>{' '}
            <p className="inline sm:block text-lg sm:text-xl mt-3">Gib dini Beschwerden i d'Suechifeld ii und erhalt Vorschläg für d'passende Medikament und Pflegemittel. Bi üs chasch du nach Symptomen sueche und schnell hilfriichi Produkt finde.</p>
            <form className="mt-10 max-w-md mx-auto lg:mx-0">
              <p className="mt-4 text-sm leading-6 text-white">
              Suech dini Symptome, find schnäll d'Linderung!
              </p>
            </form>
          </div>

          {/* Right side content: MP4 Video */}
          <div className="relative mt-10 lg:mt-0 lg:ml-10 hidden lg:block">
            <video className="rounded-full shadow-lg" width="400" autoPlay loop muted playsInline>
              <source src="/videos/SearchVideo9.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            {/* White circle for magnifying glass handle */}
            <div className="absolute bg-white w-12 h-12 rounded-full bottom-[-20px] left-[-20px] shadow-lg"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
