import { Fragment, useState, useEffect } from 'react';
import { Disclosure, Menu, Dialog } from '@headlessui/react';
import { ChevronDownIcon, FunnelIcon, MinusIcon, PlusIcon, XMarkIcon, StarIcon, ShoppingCartIcon } from '@heroicons/react/20/solid';
import { fetchAllProducts, toggleFavoriteProduct, fetchFavorites } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { HeartIcon as HeartIconOutline } from '@heroicons/react/24/outline'; // Outline heart icon
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid'; // Solid heart icon

const filters = [
  {
    id: 'price',
    name: 'Price',
    options: [
      { value: '0', label: '$0 - $25', checked: false },
      { value: '25', label: '$25 - $50', checked: false },
      { value: '50', label: '$50 - $75', checked: false },
      { value: '75', label: '$75+', checked: false },
    ],
  },
  {
    id: 'color',
    name: 'Color',
    options: [
      { value: 'lorem', label: 'lorem', checked: false },
      { value: 'ipsum', label: 'ipsum', checked: true },
    ],
  },
  {
    id: 'size',
    name: 'Size',
    options: [
      { value: 'xs', label: 'XS', checked: false },
      { value: 's', label: 'S', checked: true },
      { value: 'm', label: 'M', checked: false },
    ],
  },
];

const sortOptions = [
  { name: 'Most Popular', current: true },
  { name: 'Best Rating', current: false },
  { name: 'Newest', current: false },
  { name: 'Price: Low to High', current: false },
  { name: 'Price: High to Low', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AllProducts({ currentPage, setTotalPages }) { // Accept pagination props
  const [products, setProducts] = useState([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [favorite, setFavorite] = useState({}); // Track favorite status
  const navigate = useNavigate();

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await fetchAllProducts(currentPage, 20); // Fetch with currentPage
        setProducts(response.data.products);
        setTotalPages(response.data.totalPages); // Set total pages in parent component

        // Fetch user's favorite products
        const favoriteResponse = await fetchFavorites();
        const favoriteProducts = favoriteResponse.data.favorites;

        const favoriteMap = {};
        favoriteProducts.forEach(product => {
          product.articles.forEach(article => {
            favoriteMap[article.pharmacode] = true;
          });
        });
        setFavorite(favoriteMap);
      } catch (error) {
        console.error('Failed to fetch products or favorites:', error);
      }
    };
    getProducts();
  }, [currentPage, setTotalPages]); // Re-fetch when currentPage changes

  const handleViewProduct = (pharmacode) => {
    navigate(`/product/${pharmacode}`);
  };

  const handleFavoriteClick = async (pharmacode) => {
    const token = localStorage.getItem('token'); // Check if a user is logged in
    if (!token) {
      // If not logged in, redirect to the login page
      navigate('/loginpage');
      return;
    }

    try {
      await toggleFavoriteProduct(pharmacode);
      setFavorite((prev) => ({
        ...prev,
        [pharmacode]: !prev[pharmacode], // Toggle favorite status
      }));
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Dialog open={mobileFiltersOpen} onClose={setMobileFiltersOpen} className="relative z-40 lg:hidden">
          <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
          <div className="fixed inset-0 z-40 flex">
            <div className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  onClick={() => setMobileFiltersOpen(false)}
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4 border-t border-gray-200">
                {filters.map((section) => (
                  <Disclosure key={section.id} as="div" className="border-t border-gray-200 px-4 py-6">
                    <h3 className="-mx-2 -my-3 flow-root">
                      <Disclosure.Button className="group flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                        <span className="font-medium text-gray-900">{section.name}</span>
                        <span className="ml-6 flex items-center">
                          <PlusIcon className="h-5 w-5 group-data-[open]:hidden" />
                          <MinusIcon className="h-5 w-5 [.group:not([data-open])_&]:hidden" />
                        </span>
                      </Disclosure.Button>
                    </h3>
                    <Disclosure.Panel className="pt-6">
                      <div className="space-y-6">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              defaultValue={option.value}
                              defaultChecked={option.checked}
                              id={`filter-mobile-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label htmlFor={`filter-mobile-${section.id}-${optionIdx}`} className="ml-3 min-w-0 flex-1 text-gray-500">
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Disclosure.Panel>
                  </Disclosure>
                ))}
              </form>
            </div>
          </div>
        </Dialog>

        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">All Products</h1>

            <div className="flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                </Menu.Button>
                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            {option.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Menu>
              <button type="button" onClick={() => setMobileFiltersOpen(true)} className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden">
                <FunnelIcon className="h-5 w-5" />
                <span className="sr-only">Filters</span>
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pb-24 pt-6 grid grid-cols-1 lg:grid-cols-4 gap-x-8">
            {/* Filters on the left for large screens */}
            <form className="hidden lg:block lg:col-span-1">
              <h3 className="sr-only">Categories</h3>
              <ul role="list" className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                <li key="all">
                  <a href="#">All Products</a>
                </li>
              </ul>

              {filters.map((section) => (
                <Disclosure key={section.id} as="div" className="border-b border-gray-200 py-6">
                  <h3 className="-my-3 flow-root">
                    <Disclosure.Button className="group flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                      <span className="font-medium text-gray-900">{section.name}</span>
                      <span className="ml-6 flex items-center">
                        <PlusIcon className="h-5 w-5 group-data-[open]:hidden" />
                        <MinusIcon className="h-5 w-5 [.group:not([data-open])_&]:hidden" />
                      </span>
                    </Disclosure.Button>
                  </h3>
                  <Disclosure.Panel className="pt-6">
                    <div className="space-y-4">
                      {section.options.map((option, optionIdx) => (
                        <div key={option.value} className="flex items-center">
                          <input
                            defaultValue={option.value}
                            defaultChecked={option.checked}
                            id={`filter-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label htmlFor={`filter-${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600">
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </Disclosure>
              ))}
            </form>

            {/* Product grid */}
            <div className="lg:col-span-3 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3">
              {products.map((product) =>
                product.articles.map((article) => (
                  <div
                    key={article.pharmacode}
                    className="group relative border border-gray-200 rounded-lg bg-white shadow-sm p-4 sm:p-6 flex flex-col hover:shadow-lg transition-shadow duration-200"
                  >
                    {/* Favorite Icon */}
                    <button
                      onClick={() => handleFavoriteClick(article.pharmacode)}
                      className="absolute top-2 right-2"
                    >
                      {favorite[article.pharmacode] ? (
                        <HeartIconSolid className="h-6 w-6 text-[#e05257]" />
                      ) : (
                        <HeartIconOutline className="h-6 w-6 text-[#e05257]" />
                      )}
                    </button>

                    <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-white group-hover:opacity-90">
                      {article.hasPhoto && article.images && article.images.M.length > 0 ? (
                        <img
                          src={`http://localhost:3000/${article.images.M[0].replace(/\\/g, '/')}`}
                          alt={article.description?.description || 'No description'}
                          className="h-full w-full object-contain object-center"
                        />
                      ) : (
                        <div className="h-full w-full bg-white flex items-center justify-center text-gray-500">
                          <span>No Image Available</span>
                        </div>
                      )}
                    </div>

                    <div className="flex-grow pb-4 pt-4 text-center">
                      <h3 className="text-sm font-medium text-gray-900">{article.description?.description || 'Description not available'}</h3>
                      <p className="italic text-gray-500">{article.description?.longDescription || 'Details not available'}</p>

                      {/* Review Stars */}
                      <div className="mt-2 flex items-center justify-center">
                        {[...Array(5)].map((star, index) => (
                          <StarIcon
                            key={index}
                            className={`h-5 w-5 ${index < 4 ? 'text-yellow-400' : 'text-gray-200'}`}
                          />
                        ))}
                        <span className="ml-2 text-sm text-gray-500">(24 reviews)</span>
                      </div>

                      {article.prices && article.prices.length > 0 ? (
                        article.prices.find((price) => price.type.description === 'Publikumspreis') ? (
                          <p className="mt-2 font-medium text-gray-900">
                            {article.prices.find((price) => price.type.description === 'Publikumspreis').price} {article.prices[0].type.description}
                          </p>
                        ) : (
                          <p className="mt-2 font-medium text-gray-900">Price not available</p>
                        )
                      ) : (
                        <p className="mt-2 font-medium text-gray-900">Price not available</p>
                      )}
                    </div>

                    {/* Add to Cart Button */}
                    <div className="mt-4 flex space-x-2">
                      <button
                        onClick={() => handleViewProduct(article.pharmacode)}
                        type="button"
                        className="w-full flex items-center justify-center rounded-lg border-2 border-[#4c8350] bg-[#60a465] py-2 px-6 text-sm font-semibold text-white transition-all duration-300 hover:bg-[#4c8350] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#60a465]"
                      >
                        View Product
                      </button>

                      <button
                        type="button"
                        className="flex items-center justify-center rounded-lg border border-[#dc3c44] bg-white py-2 px-4 text-sm text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                      >
                        <ShoppingCartIcon className="h-5 w-5 text-[#dc3c44]" />
                        <span className="sr-only">Add to Cart</span>
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
