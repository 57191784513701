const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
   baseURL: 'http://dialineit.ch/api', //prod
   // baseURL: 'http://localhost:3000/api', //test
    rtlLayout: false,
    jwt: {
      secret: 'SECRET-KEY',
      timeout: '1 days',
    },
  };
  
  export default config;