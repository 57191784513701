export default function Hero() {
  return (
    <div className="relative z-30 font-sans">
      <div className="px-6 py-24 sm:px-6 sm:py-12 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-[2.50rem]">
            Dreirosen Apotheke
          </h2>
          <p className="mt-3 text-3xl font-bold tracking-tight text-gray-900 sm:text-[2.30rem]">
            Mit uns blibbts glaibasel gsund
          </p>
          <p className="mx-auto mt-5 max-w-xl text-lg leading-8 text-gray-600">
            Incididunt sint fugiat pariatur cupidatat
          </p>
          <div className="mt-5 flex items-center justify-center gap-x-6">
            <a
              href="/products"
              className="rounded-full bg-[#e05257] px-5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 animate-bubble hover:scale-110 transition-transform duration-300 ease-in-out"
            >
              Zum Shop
            </a>
          </div>
          <div className="mt-5 flex items-center justify-center gap-x-6">
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Weitere Infos <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
      <style jsx>{`
        @keyframes bubbleEffect {
          0% {
            transform: scale(0.5);
            opacity: 0;
          }
          80% {
            transform: scale(1.05);
            opacity: 1;
          }
          100% {
            transform: scale(1);
          }
        }
        .animate-bubble {
          animation: bubbleEffect 0.7s ease-out;
        }
      `}</style>
    </div>
  );
}
