import { useState } from 'react';
import AllProducts from '../components/Shop/AllProducts';
import Pagination from '../components/Shop/Pagination';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import Banner from '../components/Banner';

export default function Products() {
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(1);   // Track total pages from backend

  const handlePageChange = (page) => {
    setCurrentPage(page);  // Update current page when the user clicks on a page number or navigation button
    window.scrollTo(0, 0); // Scroll to top after changing the page
  };

  return (
    <div>
      <Banner />
      <Nav />
      <AllProducts currentPage={currentPage} setTotalPages={setTotalPages} />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <Footer />
    </div>
  );
}
