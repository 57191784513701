import { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"; // Icons for navigation

const vaccinationDetails = [
  {
    vaccination: "Zeckenimpfung",
    details: `Preis: CHF 47.35 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren
Die Zeckenimpfung wird insgesamt dreimal verabreicht. Es wird jedoch unterschieden zwischen dem normalen- und dem Schnellimpfschema.`,
  },
  {
    vaccination: "Grippeimpfung",
    details: `Preis: CHF 13.70 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren
Die Impfung ist saisonal abhängig (Start meist im Oktober / November bis Februar)`,
  },
  {
    vaccination: "Herpes Zoster",
    details: `Preis: CHF 174.65 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 18 Jahren, empfohlen wird die Impfung ab dem 65. Lebensjahr`,
  },
  {
    vaccination: "Diphtherie, Tetanus und Pertussis",
    details: `Preis: CHF 36.30 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Alter: Ab 16 Jahren`,
  },
  {
    vaccination: "Hepatitis A + B",
    details: `Hepatitis A Preis: CHF 53.00 (Impfstoff) zuzüglich CHF 20.00 (Impfservice)
Die Hepatitis A Impfung wird in zwei Dosen verabreicht.`,
  },
  {
    vaccination: "Impfung gegen Mumpf, Masern und Röteln",
    details: `Preis: CHF 36.60 zuzüglich CHF 20.00 (Impfservice) Die Impfung gegen Mumpf, Masern und Röteln wird in zwei Dosen verabreicht.`,
  },
];

export default function VaccinationSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? vaccinationDetails.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === vaccinationDetails.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <section className="relative bg-[#eff5ef] py-20 mb-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mb-12 text-center">
          <h2 className="text-5xl font-extrabold text-gray-900">
            Entdecken Sie Unsere Impfungen
          </h2>
          <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
            Schützen Sie Ihre Gesundheit mit den empfohlenen Impfungen. Wählen
            Sie die passende Impfung aus.
          </p>
        </div>

        <div className="relative overflow-hidden w-full max-w-4xl mx-auto">
          {/* Slider container */}
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {vaccinationDetails.map((faq, index) => (
              <div
                key={index}
                className="min-w-full flex-shrink-0 flex justify-center items-center px-6"
              >
                <div className="bg-white rounded-xl shadow-lg p-8 text-center max-w-md mx-auto">
                  <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                    {faq.vaccination}
                  </h3>
                  <p className="text-gray-600 mb-6">{faq.details}</p>
                  <button className="bg-gray-600 text-white px-6 py-3 rounded-lg hover:bg-gray-700 transition">
                    Mehr Erfahren
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* Left and right navigation */}
          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white p-3 rounded-full shadow-lg hover:bg-indigo-700 transition"
          >
            <FiChevronLeft size={24} />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white p-3 rounded-full shadow-lg hover:bg-indigo-700 transition"
          >
            <FiChevronRight size={24} />
          </button>
        </div>
      </div>
    </section>
  );
}
